import * as React from "react"

// markup
const ChitChatsPrivacyPage = () => {
  return (
    <main>
      <h1>ChitChats Privacy Policy</h1>
      <br/>
      <p>We don't collect or store data, period. :)</p>
    </main>
  );
}

export default ChitChatsPrivacyPage